
    import {Options, Vue} from 'vue-class-component';
    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    import MainSection from "@/components/MainSection.vue";

    @Options({
        components: {
            MainSection,
            Footer,
            Header,
        },
    })
    export default class App extends Vue {

        content: any = null;

        mounted() {

            this.fetchContent()
                .then(data => {
                    this.content = data;
                    const backgroundImage = data['content'][0]['image'];
                    if (backgroundImage) {
                        document.body.style.backgroundImage = `url('${backgroundImage.large}')`;
                    }
                });
        }

        async fetchContent() {
            const contentUrl = 'https://www.schularena.com/api/recarena/content';
            try {
                const response = await fetch(contentUrl);
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }

                return response.json();

            } catch (error) {
                console.error(error.message);
            }
        }

    }
