
import {Options, prop, Vue} from 'vue-class-component';
    import MainPanel from "@/components/MainPanel.vue";
    import LinksPanel from "@/components/LinksPanel.vue";

    class Props {
      content: any = prop({
        required: false,
      });
    }

    @Options({
        components: {
            LinksPanel,
            MainPanel,
        },
    })
    export default class MainSection extends Vue.with(Props) {

        recordingId: string | null = null;

        mounted() {
            this.recordingId = window.location.pathname.substr(1);
        }
    }
