
    import {Options, prop, Vue} from 'vue-class-component';
    import Recorder from "@/components/Recorder.vue";
    import Recording from "@/components/Recording.vue";

    class Props {
        recordingId: string = prop({
            required: true,
        });
        content: any = prop({
          required: false,
        });
    }

    @Options({
        components: {Recording, Recorder}
    })
    export default class MainPanel extends Vue.with(Props) {

        recordingId!: string;
    }
