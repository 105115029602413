

    import {Options, prop, Vue} from 'vue-class-component';
    import {recordingFile} from "@/util";
    import ShareRecording from "@/components/ShareRecording.vue";
    import Player from "@/components/Player.vue";
    import Error from "@/components/Error.vue";
    import axios from "axios";
    import config from "@/config";

    class Props {
        id: string = prop({
            required: true,
        });
        content: any = prop({
          required: false,
        });
    }

    @Options({
        components: {Player, ShareRecording, Error},
    })
    export default class Recording extends Vue.with(Props) {

        id!: string;

        error: string | null = null;

        audio: HTMLAudioElement | null = null;

        recordingInfo: any = null;

        mounted() {

            const file = recordingFile(this.id);

            console.log(file);

            this.audio = window.document.createElement('audio');
            this.audio.src = file;

            this.audio.addEventListener('error', e => {
                console.log(e);
                this.error = 'Die Aufnahme konnte leider nicht gefunden werden. Wahrscheinlich wurde sie gelöscht';
            })

          this.loadRecordingInfo();
        }

      private loadRecordingInfo() {
        axios.get(config.uploadUrl + this.id)
            .then((response) => {
              this.recordingInfo = response.data;
            })
            .catch((error) => {
              console.error(error);
            });
      }

    }
